<template>
  <b-row>
    <b-col sm="6">
      <deposit-box />
    </b-col>
    <b-col sm="6">
      <b-card title="รายการฝากล่าสุด 🙌" sm="6">
        <b-table responsive="sm" :items="items">
          <template #cell(status)="data">
            <b-badge pill :variant="data.value.variant">{{
              data.value.title
            }}</b-badge>
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { BCard, BRow, BCol, BTable, BBadge } from 'bootstrap-vue'

import DepositBox from './component/DepositBox.vue'

import { getUserData } from '../auth/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    DepositBox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  directives: {
    Ripple
  },
  data() {
    return {
      accountNo: '8162626431',
      items: [
        // {
        //   Tx: 40,
        //   date_time: 'Dickerson',
        //   amount: 'Macdonald',
        //   status: { title: 'รอดำเนินการ', variant: 'light-warning' }
        // },
        // {
        //   Tx: 21,
        //   date_time: 'Larsen',
        //   amount: 'Shaw',
        //   status: { title: 'รอดำเนินการ', variant: 'light-warning' }
        // },
        // {
        //   Tx: 89,
        //   date_time: 'Geneva',
        //   amount: 'Wilson',
        //   status: { title: 'รอดำเนินการ', variant: 'light-warning' }
        // },
        // {
        //   Tx: 38,
        //   date_time: 'Jami',
        //   amount: 'Carney',
        //   status: { title: 'รอดำเนินการ', variant: 'light-warning' }
        // },
        // {
        //   Tx: 40,
        //   date_time: 'James',
        //   amount: 'Thomson',
        //   status: { title: 'รอดำเนินการ', variant: 'light-warning' }
        // }
      ],
      user: null,
      bank: null,
      member: null,
      minimumDeposit: '',
      minimumWithdraw: '',
      transactions: []
    }
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'คัดลอกเลขที่บัญชีเรียบร้อยแล้ว',
          icon: 'BellIcon',
          variant: 'success'
        }
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'ไม่สามารถคัดลอกข้อมูลได้',
          icon: 'BellIcon',
          variant: 'danger'
        }
      })
    },
    async getActiveBank() {
      const b = await this.$http.post('/member/bank/active', {
        domain: 'betded888.com'
      })
      this.bank = b.data.bank
      this.minimumDeposit = b.data.minDeposit
      this.minimumWithdraw = b.data.minWithdraw
    },
    async getMemberInfo() {
      const member = await this.$http.get('member/info')

      if (member.status === 200) {
        return member.data
      }
      return null
    },
    transformT(txs) {
      const resp = []
      txs.forEach(element => {
        let v = 'light-warning'
        if (element.status === 'confirmed') {
          v = 'light-success'
        } else if (element.status === 'reject') {
          v = 'light-danger'
        }

        resp.push({
          Tx: element.id,
          date_time: `${element.date} ${element.time}`,
          amount: element.amount,
          status: {
            title: element.status,
            variant: v
          }
        })
      })
      return resp
    },
    async getTransactions() {
      const tx = await this.$http.get('/member/deposit/tx')
      console.log(tx.data)
      if (tx.status === 200) {
        const res = this.transformT(tx.data)
        this.items = res
      }
    }
  },
  async beforeMount() {
    this.user = getUserData()
    await this.getActiveBank()
    await this.getTransactions()
    this.member = await this.getMemberInfo()
  }
}
</script>

<style></style>
